// ** Redux Imports
import { createSlice } from "@reduxjs/toolkit";

const initialState = () => {
	const item = window.localStorage.getItem("userData");

	if (!item) {
		return {
			userData: null,
			accessToken: null,
		};
	}

	let tempData = JSON.parse(item);
	return {
		userData: tempData.user,
		accessToken: tempData.accessToken,
	};
};

export const authSlice = createSlice({
	name: "authentication",
	initialState: initialState(),
	reducers: {
		handleLogin: (state, action) => {
			state.userData = action.payload.user;
			state.accessToken = action.payload.accessToken;
			localStorage.setItem("userData", JSON.stringify(action.payload));
		},
		handleUpdateUser: (state, action) => {
			state.userData = {
				...state.userData,
				...action.payload,
			};

			localStorage.setItem(
				"userData",
				JSON.stringify({
					user: state.userData,
					accessToken: state.accessToken,
				})
			);
		},

		handleUpdateMerchantUser: (state, action) => {
			state.userData = {
				...state.userData,
				merchant: {
					...state.userData.merchant,
					...action.payload,
				},
			};

			localStorage.setItem(
				"userData",
				JSON.stringify({
					user: state.userData,
					accessToken: state.accessToken,
				})
			);
		},
		handleLogout: (state) => {
			state.userData = null;
			state.accessToken = null;

			localStorage.removeItem("userData");
		},
	},
});

export const {
	handleLogin,
	handleLogout,
	handleUpdateMerchantUser,
	handleUpdateUser,
} = authSlice.actions;

export default authSlice.reducer;
