// You can customize the template with the help of this file

//Template config options
const themeConfig = {
	app: {
		appName: "BeeChintzy",
		appLogoImage: require("../assets/images/logo/bc_logo_transparent.png"),
	},
	layout: {
		isRTL: false,
		skin: "semi-dark", // light, dark, bordered, semi-dark
		routerTransition: "fadeIn", // fadeIn, fadeInLeft, zoomIn, none or check this for more transition https://animate.style/
		type: "vertical", // vertical, horizontal
		contentWidth: "full", // full, boxed
		menu: {
			isHidden: false,
			isCollapsed: false,
		},
		navbar: {
			// ? For horizontal menu, navbar type will work for navMenu type
			type: "sticky", // static , sticky , floating, hidden
			backgroundColor: "white", // BS color options [primary, success, etc]
		},
		footer: {
			type: "hidden", // static, sticky, hidden
		},
		customizer: false,
		scrollTop: false, // Enable scroll to top button
	},
};

export default themeConfig;
