import React, { lazy, Suspense } from "react";
import ReactDOM from "react-dom/client";

import { Provider } from "react-redux";
import { store } from "./bc_redux/store";

import { ToastContainer } from "react-toastify";
import ability from "./bc_configs/acl/ability";
import { AbilityContext } from "./bc_utility/bc_context/Can";

import "./bc_configs/i18n";

import "react-perfect-scrollbar/dist/css/styles.css";

import "./assets/scss/react/libs/toastify/toastify.scss";

import "./assets/scss/core.scss";
import SpinnerComponent from "./bc_components/spinner/Fallback-spinner";

const LazyApp = lazy(() => import("./App"));

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
	<Provider store={store}>
		<Suspense fallback={<SpinnerComponent />}>
			<AbilityContext.Provider value={ability}>
				<LazyApp />
				<ToastContainer newestOnTop />
			</AbilityContext.Provider>
		</Suspense>
	</Provider>
);
